
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';


import HabilidadService from '../services/habilidad-service';
import UsuarioHabilidadService from '../services/usuariohabilidad-service';




export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule
    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {

            procedimientos: null,
            dataSourceUsuarios: [
                { id: 1, documentoid: 2, documento: 'cotizacionservicioV1.docx', fecharegistro: '10/10/2022 17:15', usuario: 'Juan Perez', servicioid: 1, clave: 'COT00058' },
                { id: 2, documentoid: 2, documento: 'cotizacionservicioV1.docx', fecharegistro: '11/10/2022 17:15', usuario: 'Jose Rodriguez', servicioid: 1, clave: 'COT00058' },
                { id: 3, documentoid: 2, documento: 'cotizacionservicioV2.docx', fecharegistro: '10/10/2022 17:15', usuario: 'Juan Perez', servicioid: 2, clave: 'COT00088' },


            ],
            dataSourceRoles: null,
            dataSourceHabilidades: [
                { id: 1, descripcion: 'Solicitud Cliente' },
                { id: 2, descripcion: 'Cotizacion' },
                { id: 3, descripcion: 'Evidencia Aprobacion' },
                { id: 4, descripcion: 'Evidencia Rechazo' },
                { id: 5, descripcion: 'Documento Evidencia Avance' },
                { id: 6, descripcion: 'Orden de Compra' },
            ], dataSourceServicios: [
                { id: 1, descripcion: 'OT0001-Asesoria Global Ambiental Tipo Poliza' },
                { id: 2, descripcion: 'OT0002-Asesoria en Procedimiento Administrativo' },
                { id: 3, descripcion: 'OT0003-Estudio Ambiental Especifico' },
                { id: 4, descripcion: 'OT0004-Evaluaciones Ambientales Laboratorio' },
                { id: 5, descripcion: 'Solicitud General' }
            ],
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Agregar Documentos',
                showTitle: true,
                width: 400,
                height: 350,
            },



        };
    },
    created() {

        this.usuariohabilidadService = new UsuarioHabilidadService();
        this.habilidadService = new HabilidadService();

    },
    mounted() {

        //this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {


            //this.usuarioService.getUsuario().then(data => this.dataSourceUsuarios = data);
            let idusuario = this.supplierId;
            let myJSON = JSON.stringify({
                pusuarioid: idusuario
            });
            this.usuariohabilidadService.getUsuarioHabilidad(myJSON).then(data => this.dataSourceUsuarios = data);
            this.habilidadService.getHabilidad().then(data => this.dataSourceHabilidades = data);
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },

        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.usuariohabilidadService.deleteUsuarioHabilidad(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {


            let idusuario = this.supplierId;
            let myJSON = JSON.stringify({
                pusuarioid: idusuario,
                phabilidadid: e.data.habilidadid,

            });


            this.usuariohabilidadService.createUsuarioHabilidad(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
